import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useMainCarousel } from "../../assets/images";
import { apiFunctions, fetchData, urlAPI } from "../../services/api.services";
import { formatPrice, setCategoryCookie } from "../../services/function";

const LandingPageProduct = () => {
  const [modalShow, setModalShow] = useState(true);
  const [popUpImg, setPopUpImg] = useState(null);
  const [headline, setHeadline] = useState(null);
  const [activePopup, setActivePopup] = useState(false);
  const [section, setSection] = useState(null);
  const handleClose = () => setModalShow(false);
  useEffect(() => {
    setModalShow(true);
    fetchData("main/header", "", (data) => {
      const Header = data.data;
      setPopUpImg(urlAPI + Header.popup);
      setHeadline(Header.headline);
      setActivePopup(Header.is_active_popup === "false" ? false : true);
    });
    fetchData("main/section", "", (data) => {
      const Section = data.data;
      setSection(Section);
    });
  }, []);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (modalShow && activePopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [modalShow, handleClose, activePopup]);

  useEffect(() => {
    const modalElement = document.getElementById("onloadModal");
    if (modalElement) {
      if (activePopup && modalShow) {
        modalElement.classList.add("show");
        modalElement.style.display = "block";
        document.body.classList.add("modal-open");
        document.body.style.overflow = "hidden";

        let backdrop = document.querySelector(".modal-backdrop");
        if (!backdrop) {
          backdrop = document.createElement("div");
          backdrop.className = "modal-backdrop fade";
          document.body.appendChild(backdrop);
          setTimeout(() => {
            backdrop.classList.add("show");
          }, 10);
        } else {
          backdrop.classList.add("show");
        }
      } else {
        modalElement.classList.remove("show");
        modalElement.style.display = "none";
        document.body.classList.remove("modal-open");
        document.body.style.overflow = "auto";

        const backdrop = document.querySelector(".modal-backdrop");
        if (backdrop) {
          backdrop.classList.remove("show");
          setTimeout(() => {
            backdrop.remove();
          }, 150);
        }
      }
    }
  }, [activePopup, modalShow]);

  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);

  useEffect(() => {
    apiFunctions.products((data) => {
      setProduct(data.data);
      // console.log(data.data,'products');
    });
    apiFunctions.category((data) => {
      setCategory(data);
      // console.log(data, 'category');
    });
  }, []);

  const mainCarouselImages = useMainCarousel();

  return (
    <>
      {activePopup && (
        <div
          class="modal fade PopUp-modal"
          id="onloadModal"
          tabindex="-1"
          aria-labelledby="onloadModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" ref={modalRef}>
            <div class="modal-content">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
              <div class="modal-body">
                <div class="deal">
                  <img src={popUpImg} alt="" style={{ maxHeight: "500px" }} />
                  <div class="deal-PopUp">
                    <a href="/product" class="btn hover-up">
                      Lihat Produk<i class="fi-rs-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div
        class="modal fade PopUp-modal"
        id="onloadModal"
        tabindex="-1"
        aria-labelledby="onloadModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" ref={modalRef}>
          <div class="modal-content">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
            <div class="modal-body">
              <div class="deal">
                <img src={popUpImg} alt="" style={{ maxHeight: "500px" }} />
                <div class="deal-PopUp">
                  <a href="/product" class="btn hover-up">
                    Lihat Produk<i class="fi-rs-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Carousel indicators={false} controls={false} interval="5000">
        {mainCarouselImages.map((item) => (
          <Carousel.Item>
            <img src={urlAPI + item} alt="" className="w-100" />
          </Carousel.Item>
        ))}
      </Carousel>

      <h2 class="text-center mt-4 title-head">{headline}</h2>
      {section &&
        section.map((item) => {
          const proSection = product
            .filter(
              (productItem) =>
                productItem.item_category_id === parseInt(item.category_id)
            )
            .sort(
              (a, b) => new Date(b.last_modified) - new Date(a.last_modified)
            )
            .slice(0, item.pageView == "large" ? 5 : 12);
          return (
            <>
              <div class="container my-4">
                <h5 class="text-center mt-3 mb-4">{item.name}</h5>
                <div class="row product-grid-4 mx-auto">
                  {proSection.map((product) => (
                    <div
                      class={`${
                        item.pageView == "large"
                          ? "col-lg-1-5 col-md-4 col-sm-6 col-6"
                          : "col-lg-2 col-md-4 col-sm-6 col-6"
                      }`}
                    >
                      <div
                        class="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                        data-wow-delay=".4s"
                      >
                        <div class="product-img-action-wrap">
                          <div
                            class="product-img product-img-zoom"
                            style={{ width: "100%", height: "250px" }}
                          >
                            <a href={`/product/${product.item_group_id}`}>
                              <img
                                class="h-100 w-100"
                                src={product.thumbnail}
                                alt=""
                              />
                              {/* <img class="hover-img" src="assets/imgs/shop/product-4-2.jpg" alt="" /> */}
                            </a>
                          </div>
                          {/* <div class="product-badges product-badges-position product-badges-mrg">
                            <span class="hot">40%</span>
                        </div> */}
                        </div>
                        <div class="product-content-wrap">
                          <div class="product-stockLP mb-5">
                            {/* <span>Ready Stock</span> */}
                          </div>
                          <h2
                            class="product-name text-truncate"
                            title={product.item_name}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <a href={`/product/${product.item_group_id}`}>
                              {product.item_name}
                            </a>
                          </h2>
                          <div className="product-category">
                            <span class="font-small text-muted">
                              <a
                                onClick={() =>
                                  setCategoryCookie(parseInt(item.category_id))
                                }
                              >
                                {category
                                  .filter(
                                    (cat) =>
                                      cat.category_id ===
                                      product.item_category_id
                                  )
                                  .map((cat) => cat.category_name)}
                              </a>
                            </span>
                          </div>
                          <div class="product-card-bottom flex-wrap gap-2">
                            <div class="product-price">
                              <span>{formatPrice(product.sell_price)}</span>
                              {/* <span class="old-price">Rp 280.000</span> */}
                            </div>
                            <div class="add-cart">
                              <a
                                class="add"
                                href={`/product/${product.item_group_id}`}
                              >
                                <i class="fi-rs-shopping-cart mr-5"></i>Tambah{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {item.is_image_active == "1" && (
                  <div class="row">
                    <div class="col p-0">
                      <a href="/product">
                        <img
                          src={urlAPI + item.image_url}
                          alt=""
                          class="rounded"
                        />
                      </a>
                    </div>
                  </div>
                )}
              </div>
              {item.is_video_active == "1" && (
                <video width="100%" height="auto" autoPlay muted loop>
                  <source src={urlAPI + item.video_url} type="video/mp4" />
                </video>
              )}
              {item.is_button_active == "1" && (
                <div className="container my-4 pb-4 text-center">
                  <a href={item.button_link}>
                    <button className="btn btn-dark px-3 py-2 rounded-pill border-0">
                      {item.button_text}
                    </button>
                  </a>
                </div>
              )}
            </>
          );
        })}
    </>
  );
};

export default LandingPageProduct;
