import React, { useEffect, useState } from "react";
import { userInfo } from "./AuthProvider";
import {
  addAbout,
  apiFunctions,
  approvalOrder,
  deleteBlog,
  deleteData,
  fetchAllCourier,
  fetchCourier,
  fetchData,
  fetchHistory,
  getAbout,
  getApprovalOrder,
  getBlog,
  postData,
  updateData,
  urlAPI,
} from "../../services/api.services";
import AlertComponent, { useAlert } from "../../components/commons/alert";
import QuillEditor from "../../components/commons/quilleditor";
import { ModalComponent, ModalDetail, ModalDiscount } from "./ModalForm";
import SelectAllCategory from "../../components/commons/category";
import { EditArtikel, TambahArtikel } from "../Artikel/formArtikel";
import "./Myswitch.css";

function Account() {
  const [alert, showAlert] = useAlert();
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [orderHistory, setOrderHistory] = useState([]);
  const [agenDiscount, setAgenDiscount] = useState("");
  const [resellerDiscount, setResellerDiscount] = useState("");
  const renderTruncatedHTML = (htmlString, maxLength) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    let textContent = tempDiv.textContent || tempDiv.innerText || "";
    if (textContent.length > maxLength) {
      textContent = textContent.substr(0, maxLength) + "...";
    }
    tempDiv.innerHTML = textContent;
    return tempDiv.innerHTML;
  };
  // set min payment
  const [minimumPayment, setMinimumPayment] = useState("");
  const [serviceFee, setServiceFee] = useState("");
  useEffect(() => {
    apiFunctions.setting((data) => {
      setMinimumPayment(formatCurrency(data.minimum_payment));
      setServiceFee(formatCurrency(data.service_fee));
    });
  }, []);
  const formatCurrency = (value) => {
    if (typeof value === "number") {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(value);
    }
    return "Rp 0";
  };
  const parseCurrency = (value) => {
    return value.replace(/\D/g, "");
  };
  const handleInputChange = (e, setter) => {
    const { value } = e.target;
    const numericValue = parseCurrency(value.toString());
    const formattedValue = numericValue
      ? formatCurrency(parseInt(numericValue, 10))
      : "Rp 0";
    setter(formattedValue);
  };

  const handleDiscountInputChange = (event, setFunction) => {
    setFunction(event.target.value);
  };

  const handleDiscountSubmit = async (event) => {
    event.preventDefault();
    const response = await updateData(
      "setting/discount",
      {
        agen: agenDiscount,
        reseller: resellerDiscount,
      },
      headers
    );
    if (response) {
      showAlert("Diskon berhasil diperbarui.", "success");
    } else {
      showAlert("Gagal memperbarui diskon.", "danger");
    }
  };

  useEffect(() => {
    // fetchOrderHistory();
  }, []);

  const fetchOrderHistory = async () => {
    try {
      const data = await fetchHistory();
      setOrderHistory(data);
    } catch (error) {
      console.error("Error fetching order history:", error);
      showAlert("Error fetching order history", "danger");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      minimum_payment: parseInt(parseCurrency(minimumPayment), 10) || 0,
      service_fee: parseInt(parseCurrency(serviceFee), 10) || 0,
    };
    const response = await postData("setting/save", data);
    if (response) {
      showAlert("Setting biaya servis dan min. pembayaran berhasil", "success");
    } else {
      showAlert("Setting biaya servis dan min. pembayaran gagal", "danger");
    }
  };
  // member
  const [getNewMember, setNewMember] = useState([]);
  const [getAgen, setAgen] = useState([]);
  const [getReseller, setReseller] = useState([]);
  const [getAdmin, setAdmin] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [getAllMember, setAllMember] = useState([]);
  const headers = {
    Authorization: "Bearer " + userInfo.access_token,
  };
  useEffect(() => {
    if (userInfo.user.role.name === "superadmin") {
      apiFunctions.getMember((data) => {
        const dataMember = data.data;
        const sortedDataMember = dataMember.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setNewMember(sortedDataMember.filter((item) => item.approved == 0));
        setAdmin(sortedDataMember.filter((item) => item.role_id == 1));
        setAgen(
          sortedDataMember.filter(
            (item) => item.role_id == 2 && item.approved == 1
          )
        );
        setReseller(
          sortedDataMember.filter(
            (item) => item.role_id == 3 && item.approved == 1
          )
        );
        setAllMember(dataMember);
      }, showAlert);
    }
  }, []);

  const handleApprove = async (id) => {
    const endpoint = `user/approve/${id}`;
    const response = await postData(endpoint, {}, headers);
    if (response) {
      const approvedMember = response.data.user;
      setNewMember((prevMembers) =>
        prevMembers.filter((member) => member.id !== id)
      );
      if (approvedMember.role_id === 2) {
        setAgen((prevMembers) => [approvedMember, ...prevMembers]);
      }
      if (approvedMember.role_id === 3) {
        setReseller((prevMembers) => [approvedMember, ...prevMembers]);
      }
    } else {
      console.log("Failed to approve user");
    }
  };

  const handleEdit = (data) => {
    handleShowModal("Admin", data);
  };

  const handleDelete = async (id) => {
    const endpoint = `user/destroy/${id}`;
    const response = await deleteData(endpoint, {}, headers);
    if (response) {
      showAlert("Data berhasil dihapus!", "success");
      setNewMember((prevMembers) =>
        prevMembers.filter((member) => member.id !== id)
      );
      setAdmin((prevMembers) =>
        prevMembers.filter((member) => member.id !== id)
      );
      setAgen((prevMembers) =>
        prevMembers.filter((member) => member.id !== id)
      );
      setReseller((prevMembers) =>
        prevMembers.filter((member) => member.id !== id)
      );
    } else {
      showAlert("Data gagal dihapus!", "danger");
    }
  };

  // landingpage
  const [categories, setCategories] = useState([]);
  const [menuFooter, setMenuFooter] = useState([]);
  const [storeName, setStoreName] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [description, setDescription] = useState("");
  const [headline, setHeadline] = useState("");
  const [popup, setPopup] = useState(null);
  const [activePopup, setActivePopup] = useState(false);
  const [existingPopup, setExistingPopup] = useState("");
  const [changePopup, setChangePopup] = useState("");

  const [carouselList, setCarouselList] = useState([]);
  const [paymentName, setPaymentName] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [shipmentName, setShipmentName] = useState([]);
  const [shipmentList, setShipmentList] = useState([]);
  const [sectionList, setSectionList] = useState([]);

  useEffect(() => {
    apiFunctions.category((data) => {
      setCategories(data);
    });
    fetchData(
      "order/history",
      "",
      (data) => {
        setOrderHistory(data.data);
      },
      headers
    );
    if (
      userInfo.user.role.name === "Admin" ||
      userInfo.user.role.name === "superadmin"
    ) {
      fetchData(
        "setting/discount",
        "",
        (data) => {
          setAgenDiscount(data.agen);
          setResellerDiscount(data.reseller);
        },
        headers
      );

      fetchData("main/header", "", (data) => {
        const header = data.data;
        setHeadline(header.headline || "");
        setExistingPopup(header.popup || "");
        setActivePopup(header.is_active_popup === "false" ? false : true);
      });
      fetchData("footer/menu", "", (data) => {
        setMenuFooter(data.data.menus);
        const footer = data.data.settings;
        setStoreName(footer.store_name || "");
        setWhatsapp(footer.whatsapp || "");
        setDescription(footer.store_description || "");
      });
      fetchData("main/carousel", "", (data) => {
        const carousel = data.data;
        const sortedCarousel = carousel.sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );
        setCarouselList(sortedCarousel);
      });
      fetchData("footer/payment", "", (data) => {
        const payment = data.data;
        const sortedPayment = payment.paymentMethods.sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );
        setPaymentName(payment.settings.payment_menu);
        setPaymentList(sortedPayment);
      });
      fetchData("footer/shipment", "", (data) => {
        const shipment = data.data;
        const sortedShipment = shipment.shipmentMethods.sort(
          (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
        );
        setShipmentName(shipment.settings.shipment_menu);
        setShipmentList(sortedShipment);
      });
      fetchData("main/section", "", (data) => {
        const section = data.data;
        setSectionList(section);
      });
    }
  }, []);

  const handlePopupChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPopup(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setChangePopup(e.target.result); // Update the image preview
      };
      reader.readAsDataURL(file);
    } else {
      setPopup(null);
    }
  };
  const handleFormHeaderFooter = async (e) => {
    e.preventDefault();
    const footerData = {
      store_name: storeName,
      store_description: description,
      whatsapp: whatsapp,
    };
    const headerData = new FormData();
    headerData.append("headline", headline);
    headerData.append("is_active_popup", activePopup);
    if (popup) {
      headerData.append("popup", popup); // Append the file with the name 'image'
    }
    // Update footer data
    await postData("footer/menu/setting/update", footerData);
    // Update header data
    await postData("main/header/update", headerData, {
      "Content-Type": "multipart/form-data",
    });
    showAlert("Data berhasil diupdate!", "success");
  };
  const handleEditCarousel = (data) => {
    handleShowModal("Carousel", data);
  };
  const handleDeleteCarousel = async (id) => {
    const endpoint = `main/carousel/${id}/destroy`;
    const response = await deleteData(endpoint, {}, headers);
    if (response) {
      showAlert("Data berhasil dihapus!", "success");
      setCarouselList((prevList) => prevList.filter((item) => item.id !== id));
    } else {
      showAlert("Data gagal dihapus!", "danger");
    }
  };
  const handleFormPaymentSet = async (e) => {
    e.preventDefault();
    const footerData = {
      payment_menu: paymentName,
    };
    await postData("footer/payment/setting/update", footerData);
    showAlert("Data berhasil diupdate!", "success");
  };
  const handleEditPayment = (data) => {
    handleShowModal("Metode Pembayaran", data);
  };
  const handleDeletePayment = async (id) => {
    const endpoint = `footer/payment/${id}/destroy`;
    const response = await deleteData(endpoint, {}, headers);
    if (response) {
      showAlert("Data berhasil dihapus!", "success");
      setPaymentList((prevList) => prevList.filter((item) => item.id !== id));
    } else {
      console.log("Failed to delete payment");
      showAlert("Data gagal dihapus!", "danger");
    }
  };
  const handleFormShipmentSet = async (e) => {
    e.preventDefault();
    const footerData = {
      shipment_menu: shipmentName,
    };
    await postData("footer/shipment/setting/update", footerData);
    showAlert("Data berhasil diupdate!", "success");
  };
  const handleEditShipment = (data) => {
    handleShowModal("Metode Pengiriman", data);
  };
  const handleDeleteShipment = async (id) => {
    const endpoint = `footer/shipment/${id}/destroy`;
    const response = await deleteData(endpoint, {}, headers);
    if (response) {
      showAlert("Data berhasil dihapus!", "success");
      setShipmentList((prevList) => prevList.filter((item) => item.id !== id));
    } else {
      console.log("Failed to delete payment");
      showAlert("Data gagal dihapus!", "danger");
    }
  };
  const handleEditSection = (data) => {
    handleShowModal("Tampilan Produk", data);
  };
  const handleDeleteSection = async (id) => {
    const endpoint = `main/section/${id}/destroy`;
    const response = await deleteData(endpoint, {}, headers);
    if (response) {
      showAlert("Data berhasil dihapus!", "success");
      setSectionList((prevList) => prevList.filter((item) => item.id !== id));
    } else {
      console.log("Failed to delete payment");
      showAlert("Data gagal dihapus!", "danger");
    }
  };
  const handleEditMenuFooter = (data) => {
    handleShowModal("Menu Bawah", data);
  };
  const handleDeleteMenuFooter = async (id) => {
    const endpoint = `footer/menu/${id}/destroy`;
    const response = await deleteData(endpoint, {}, headers);
    if (response) {
      showAlert("Data berhasil dihapus!", "success");
      setSectionList((prevList) => prevList.filter((item) => item.id !== id));
    } else {
      console.log("Failed to delete payment");
      showAlert("Data gagal dihapus!", "danger");
    }
  };

  const handleShowModal = (tabName, editItem = null) => {
    setActiveTab(tabName);
    setEditMode(!!editItem);
    setEditData(editItem || {});
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditMode(false);
    setEditData({});
    setShowModal(false);
  };

  // set courier
  const [couriers, setCouriers] = useState([]);
  const [selectedCouriers, setSelectedCouriers] = useState([]);

  useEffect(() => {
    const fetchCouriersData = async () => {
      try {
        const couriersData = await fetchAllCourier();
        setCouriers(couriersData);
      } catch (error) {
        console.error("Error fetching couriers:", error);
      }
    };
    fetchCouriersData();

    const fetchCheckedCouriers = async () => {
      try {
        const checkedCouriers = await fetchCourier();
        setSelectedCouriers(checkedCouriers);
      } catch (error) {
        console.error("Error fetching checked couriers:", error);
      }
    };
    fetchCheckedCouriers();
  }, []);

  const handleCheckboxChange = (event) => {
    const courier = event.target.value;
    if (event.target.checked) {
      setSelectedCouriers([...selectedCouriers, courier]);
    } else {
      setSelectedCouriers(selectedCouriers.filter((c) => c !== courier));
    }
  };

  const handleSubmitCourier = async (event) => {
    event.preventDefault();

    try {
      const response = await postData("ekspedisi/setActiveCouriers", {
        couriers: selectedCouriers,
      });
      showAlert("Setting kurir berhasil", "success");
    } catch (error) {
      showAlert("Setting kurir gagal", "danger");
    }
  };
  const renderCheckboxes = () => {
    const groups = [];
    for (let i = 0; i < couriers.length; i += 5) {
      groups.push(
        <div className="form-check form-check-inline" key={`group-${i}`}>
          {couriers.slice(i, i + 5).map((courier, index) => (
            <div className="custome-checkbox" key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                value={courier}
                id={`checkbox-${courier.replace(/\s+/g, "-").toLowerCase()}`}
                onChange={handleCheckboxChange}
                checked={selectedCouriers.includes(courier)}
              />
              <label
                className="form-check-label text-uppercase"
                htmlFor={`checkbox-${courier
                  .replace(/\s+/g, "-")
                  .toLowerCase()}`}
              >
                {courier}
              </label>
            </div>
          ))}
        </div>
      );
    }
    return groups;
  };

  // Detail Pesanan
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const handleShowDetail = (orderId, type) => {
    setSelectedOrderId(orderId);
    setSelectedOrderType(type);
    setShowModalDetail(true);
  };

  const handleCloseDetail = () => {
    setShowModalDetail(false);
    setSelectedOrderId(null);
  };

  // handle diskon
  const [showModalDiskon, setShowModalDiskon] = useState(false);
  const [selectedOrderIdDiskon, setSelectedOrderIdDiskon] = useState(null);

  const handleDiskon = (id) => {
    const currentDiscount =
      getAgen.find((agent) => agent.id === id)?.special_discount || 0;
    setShowModalDiskon(true);
    setSelectedOrderIdDiskon(id);
  };

  const handleCloseDiskon = () => {
    setShowModalDiskon(false);
    setSelectedOrderIdDiskon(null);
  };

  const currentDiscount =
    getAllMember.find((member) => member.id === selectedOrderIdDiskon)
      ?.special_discount || 0;

  const updateAgenDiscount = (userId, newDiscount) => {
    setAllMember((prevAgen) =>
      prevAgen.map((agent) =>
        agent.id === userId
          ? { ...agent, special_discount: newDiscount }
          : agent
      )
    );
  };

  // logout
  const Logout = () => {
    localStorage.removeItem("user-info");
    window.location.href = "/login";
  };

  const [activeTabApproval, setActiveTabApproval] = useState("1");
  const [approvalOrders, setApprovalOrders] = useState([]);
  const [unApprovalOrders, setUnApprovalOrders] = useState([]);

  useEffect(() => {
    const fetchApprovalOrders = async () => {
      try {
        const approvalOrdersData = await getApprovalOrder("1");
        setApprovalOrders(approvalOrdersData);
      } catch (error) {
        console.error("Error fetching approval orders:", error);
      }
    };

    const fetchUnapprovedOrders = async () => {
      try {
        const unapprovedOrders = await getApprovalOrder("0");
        setUnApprovalOrders(unapprovedOrders);
      } catch (error) {
        console.error("Failed to fetch unapproved orders:", error);
      }
    };

    fetchApprovalOrders();
    fetchUnapprovedOrders();
  }, []);

  const handleApproveOrder = async (order_id) => {
    if (!order_id || order_id === 0) {
      showAlert("Order ID tidak valid", "error");
      return;
    }
    try {
      const response = await approvalOrder(order_id);
      if (response) {
        showAlert("Pesanan berhasil disetujui", "success");
        setUnApprovalOrders((prevOrders) =>
          prevOrders.filter((order) => order.order_id !== order_id)
        );
      }
    } catch (error) {
      console.error("Error fetching approval orders:", error);
    }
  };

  const [artikel, setArtikel] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(null);

  const fetchDataArtikel = async () => {
    try {
      const data = await getBlog();
      setArtikel(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataArtikel();
  }, []);

  const handleTambahClick = () => {
    setIsFormVisible(true);
    setIsEditing(false);
    setCurrentArticle(null); // No article selected for adding
  };

  const handleEditClick = (id) => {
    const articleToEdit = artikel.find((item) => item.id === id);
    setCurrentArticle(articleToEdit);
    setIsFormVisible(true);
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsFormVisible(false);
    setCurrentArticle(null);
  };

  const refreshArtikel = () => {
    fetchDataArtikel(); // Perbarui daftar artikel setelah perubahan
  };
  const handleDeleteArtikel = async (id) => {
    const response = await deleteBlog(id);
    if (response) {
      showAlert("Artikel berhasil dihapus!", "success");
      setArtikel((prevArtikels) =>
        prevArtikels.filter((item) => item.id !== id)
      );
    } else {
      console.error("Error deleting article:", response);
      showAlert("Data gagal dihapus!", "danger");
    }
  };

  const [formAbout, setFormAbout] = useState([]);
  const [aboutTitle, setAboutTitle] = useState("");
  const [aboutDescription, setAboutDescription] = useState("");
  const [aboutImage, setAboutImage] = useState(null);
  const [existingImage, setExistingImage] = useState("");
  const [changeABoutImage, setChangeABoutImage] = useState("");

  useEffect(() => {
    const fetchDataAbout = async () => {
      try {
        const response = await getAbout();
        if (response) {
          setFormAbout(response);
          setAboutTitle(response.about_title);
          setAboutDescription(response.about_description);
          setExistingImage(response.about_image);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAbout();
  }, []);

  const handleAboutImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAboutImage(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setChangeABoutImage(e.target.result); // Update the image preview
      };
      reader.readAsDataURL(file);
    } else {
      setAboutImage(null);
    }
  };
  const handleSubmitAbout = async (e) => {
    e.preventDefault();
    const aboutData = {
      about_image: aboutImage,
      about_description: aboutDescription,
      about_title: aboutTitle,
    };
    const data = new FormData();
    data.append("data", aboutData);
    if (aboutImage) {
      data.append("image", aboutImage); // Append the file with the name 'image'
    }
    try {
      const response = await addAbout(aboutData);
      showAlert("Tentang Kami Berhasil ditambah.", "success");
    } catch (error) {
      showAlert("Tentang Kami Gagal ditambah", "danger");
    }
  };

  return (
    <div id="account">
      <AlertComponent alert={alert} />
      <main class="main pages">
        <div class="page-header breadcrumb-wrap">
          <div class="container">
            <div class="breadcrumb">
              <a href="/" rel="nofollow">
                <i class="fi-rs-home mr-5"></i>Beranda
              </a>
              <span></span> Akun
            </div>
          </div>
        </div>
        <div class="page-content pt-20 pt-md-5 pb-150">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 m-auto">
                <div class="row">
                  <div class="col-md-3">
                    <div class="dashboard-menu">
                      <ul class="nav flex-column" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="dashboard-tab"
                            data-bs-toggle="tab"
                            href="#dashboard"
                            role="tab"
                            aria-controls="dashboard"
                            aria-selected="false"
                          >
                            <i class="fi-rs-settings-sliders mr-10"></i>Akun
                          </a>
                        </li>
                        {(userInfo && userInfo.user.role_id === "1") ||
                          (userInfo.user.role_id === "4" && (
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="artikel-tab"
                                data-bs-toggle="tab"
                                href="#artikel"
                                role="tab"
                                aria-controls="artikel"
                                aria-selected="false"
                              >
                                <i class="fi-rs-settings-sliders mr-10"></i>
                                Artikel
                              </a>
                            </li>
                          ))}
                        {userInfo && userInfo.user.role_id === "4" && (
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="account-detail-tab"
                              data-bs-toggle="tab"
                              href="#account-detail"
                              role="tab"
                              aria-controls="account-detail"
                              aria-selected="true"
                            >
                              <i class="fi-rs-users mr-10"></i>Member
                            </a>
                          </li>
                        )}
                        {(userInfo && userInfo.user.role_id === "1") ||
                          (userInfo.user.role_id === "4" && (
                            <>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="landing-page-tab"
                                  data-bs-toggle="tab"
                                  href="#landing-page"
                                  role="tab"
                                  aria-controls="landing-page"
                                  aria-selected="true"
                                >
                                  <i class="fi-rs-settings-sliders mr-10"></i>
                                  Halaman Depan
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="track-orders-tab"
                                  data-bs-toggle="tab"
                                  href="#track-orders"
                                  role="tab"
                                  aria-controls="track-orders"
                                  aria-selected="false"
                                >
                                  <i class="fi-rs-user mr-10"></i>Set Kurir &
                                  Kategori
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="discounts-tab"
                                  data-bs-toggle="tab"
                                  href="#discounts"
                                  role="tab"
                                  aria-controls="discounts"
                                  aria-selected="true"
                                >
                                  <i className="fi-rs-user mr-10"></i> Diskon
                                  Agen & Reseller
                                </a>
                              </li>
                            </>
                          ))}
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="orders-tab"
                            data-bs-toggle="tab"
                            href="#orders"
                            role="tab"
                            aria-controls="orders"
                            aria-selected="false"
                          >
                            <i class="fi-rs-shopping-bag mr-10"></i>Riwayat
                            Pesanan
                          </a>
                        </li>
                        {(userInfo && userInfo.user.role_id === "1") ||
                          (userInfo.user.role_id === "4" && (
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="orders-tab"
                                data-bs-toggle="tab"
                                href="#orders-approval"
                                role="tab"
                                aria-controls="orders"
                                aria-selected="false"
                              >
                                <i class="fi-rs-shopping-bag mr-10"></i>Setujui
                                Pesanan
                              </a>
                            </li>
                          ))}
                        <li class="nav-item">
                          <a class="nav-link" onClick={Logout}>
                            <i class="fi-rs-sign-out mr-10"></i>Keluar
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="tab-content account dashboard-content pl-50">
                      <div
                        class="tab-pane fade active show"
                        id="dashboard"
                        role="tabpanel"
                        aria-labelledby="dashboard-tab"
                      >
                        <div class="card">
                          <div class="card-header">
                            <h3 class="mb-0">
                              Hello {userInfo ? userInfo.user.name : ""}!
                            </h3>
                          </div>
                          <div class="card-body">
                            <p>
                              Selamat datang di akun Delfia Fashion!
                              {/* Anda dapat dengan mudah mengelola produk, menangani pesanan, dan mendapatkan gambaran menyeluruh untuk meningkatkan efisiensi Anda. */}
                            </p>
                            {(userInfo && userInfo.user.role_id === "1") ||
                              (userInfo.user.role_id === "4" && (
                                <form
                                  class="contact-form-style my-4"
                                  onSubmit={handleSubmit}
                                >
                                  <div class="input-style mb-20">
                                    <label className="w-100">
                                      Biaya Servis
                                    </label>
                                    <input
                                      name="service_fee"
                                      placeholder="Biaya Servis"
                                      type="text"
                                      class="w-50"
                                      value={serviceFee}
                                      onChange={(e) =>
                                        handleInputChange(e, setServiceFee)
                                      }
                                    />
                                  </div>
                                  <div class="input-style mb-20">
                                    <label className="w-100">
                                      Minimum Pembayaran
                                    </label>
                                    <input
                                      name="minimum_payment"
                                      placeholder="Minimum Pembayaran"
                                      type="text"
                                      class="w-50"
                                      value={minimumPayment}
                                      onChange={(e) =>
                                        handleInputChange(e, setMinimumPayment)
                                      }
                                    />
                                  </div>
                                  <button
                                    class="submit submit-auto-width"
                                    type="submit"
                                  >
                                    Set
                                  </button>
                                </form>
                              ))}
                          </div>
                        </div>
                      </div>
                      {(userInfo && userInfo.user.role_id === "1") ||
                        (userInfo.user.role_id === "4" && (
                          <div
                            className="tab-pane fade show"
                            id="artikel"
                            role="tabpanel"
                          >
                            {isFormVisible ? (
                              isEditing ? (
                                <EditArtikel
                                  article={currentArticle}
                                  onCancel={handleCancelClick}
                                  showAlert={showAlert}
                                  refreshArtikel={refreshArtikel}
                                />
                              ) : (
                                <TambahArtikel
                                  onCancel={handleCancelClick}
                                  showAlert={showAlert}
                                  refreshArtikel={refreshArtikel}
                                />
                              )
                            ) : (
                              <div className="card">
                                <div className="card-header">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h3 className="mb-0">Artikel</h3>
                                    <button
                                      className="btn btn-small"
                                      onClick={handleTambahClick}
                                    >
                                      Tambah
                                    </button>
                                  </div>
                                </div>

                                <div className="card-body">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th>Judul Artikel</th>
                                          <th>Gambar</th>
                                          <th>Deskripsi</th>
                                          <th>Aksi</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {artikel.length > 0 ? (
                                          artikel
                                            .sort(
                                              (a, b) =>
                                                new Date(b.updated_at) -
                                                new Date(a.updated_at)
                                            )
                                            .map((item) => (
                                              <tr key={item.id}>
                                                <td>{item.title}</td>
                                                <td>
                                                  <img
                                                    src={urlAPI + item.image}
                                                    alt={item.title}
                                                    style={{
                                                      maxWidth: "100px",
                                                      marginBottom: "10px",
                                                    }}
                                                  />
                                                </td>
                                                <td>
                                                  <p>
                                                    {item.description.replace(
                                                      /<\/?p>/g,
                                                      ""
                                                    )}
                                                  </p>
                                                </td>
                                                <td>
                                                  <div className="d-flex align-items-center gap-3">
                                                    <button
                                                      className="btn btn-sm"
                                                      onClick={() =>
                                                        handleEditClick(item.id)
                                                      }
                                                    >
                                                      Edit
                                                    </button>
                                                    <button
                                                      className="btn btn-sm"
                                                      onClick={() =>
                                                        handleDeleteArtikel(
                                                          item.id
                                                        )
                                                      }
                                                    >
                                                      Hapus
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))
                                        ) : (
                                          <tr>
                                            <td colSpan="4">
                                              Belum ada artikel
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      {userInfo && (
                        <div
                          className="tab-pane fade show"
                          id="orders"
                          role="tabpanel"
                        >
                          <div className="card">
                            <div className="card-header">
                              <h3 className="mb-0">Pesanan Anda</h3>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Nomor Pesanan</th>
                                      <th>Tanggal Pesanan</th>
                                      <th>Total</th>
                                      <th>Aksi</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {orderHistory.length > 0 ? (
                                      orderHistory.map((order) => (
                                        <tr key={order.id}>
                                          <td>#{order.order_id}</td>
                                          <td>
                                            {new Date(
                                              order.created_at
                                            ).toLocaleDateString()}
                                          </td>
                                          {/* <td>{order.status}</td> */}
                                          <td>
                                            {formatCurrency(
                                              Number(order.total)
                                            )}
                                          </td>
                                          <td>
                                            <button
                                              onClick={() =>
                                                handleShowDetail(
                                                  order.order_id,
                                                  order.type
                                                )
                                              }
                                              style={{
                                                border: "none",
                                                backgroundColor: "#b83bb3",
                                                color: "white",
                                              }}
                                              className="rounded-3 p-2 fw-bold"
                                            >
                                              Detail Pesanan
                                            </button>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="4">Belum ada order</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {selectedOrderId && (
                              <ModalDetail
                                showModalDetail={showModalDetail}
                                handleClose={handleCloseDetail}
                                orderId={selectedOrderId}
                                type={selectedOrderType}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {(userInfo && userInfo.user.role_id === "1") ||
                        (userInfo.user.role_id === "4" && (
                          <>
                            <div
                              class="tab-pane fade"
                              id="track-orders"
                              role="tabpanel"
                              aria-labelledby="track-orders-tab"
                            >
                              <div className="card mt-4">
                                <div className="card-header py-0">
                                  <ul
                                    className="nav nav-tabs card-header-tabs"
                                    id="inner-tabs"
                                    role="tablist"
                                  >
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        id="set-kurir-tab"
                                        data-toggle="tab"
                                        href="#set-kurir"
                                        role="tab"
                                        aria-controls="set-kurir"
                                        aria-selected="true"
                                      >
                                        Kurir
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="set-category-tab"
                                        data-toggle="tab"
                                        href="#set-category"
                                        role="tab"
                                        aria-controls="set-category"
                                        aria-selected="false"
                                      >
                                        Kategori
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="card-body">
                                  <div
                                    className="tab-content"
                                    id="inner-tabs-content"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="set-kurir"
                                      role="tabpanel"
                                      aria-labelledby="set-kurir-tab"
                                    >
                                      <h4 class="mb-0">Set Kurir</h4>
                                      <div>
                                        <p>Pilih Kurir yang Akan Ditampilkan</p>
                                        <form onSubmit={handleSubmitCourier}>
                                          {renderCheckboxes()}
                                          <button
                                            className="d-block submit submit-auto-width mt-20 btn-sm"
                                            type="submit"
                                          >
                                            Set
                                          </button>
                                        </form>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="set-category"
                                      role="tabpanel"
                                      aria-labelledby="set-category-tab"
                                    >
                                      <h4>Kategori</h4>
                                      <div>
                                        <p>
                                          Pilih Kategori yang Akan Ditampilkan
                                        </p>
                                        <SelectAllCategory
                                          showAlert={showAlert}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div class="card">
                                            <div class="card-header">
                                                <h3 class="mb-0">Set Kurir</h3>
                                            </div>
                                            <div class="card-body contact-from-area">
                                                <p>Pilih Kurir yang akan ditampilkan</p>
                                                <form onSubmit={handleSubmitCourier}>
                                                    {renderCheckboxes()}
                                                    <button className="d-block submit submit-auto-width mt-20" type="submit">Set</button>
                                                </form>
                                            </div>
                                        </div> */}
                            </div>
                            <div
                              class="tab-pane fade"
                              id="account-detail"
                              role="tabpanel"
                              aria-labelledby="account-detail-tab"
                            >
                              <div class="card">
                                <div class="card-header pb-0">
                                  <h3>Daftar Anggota</h3>
                                </div>
                              </div>
                              <div className="card mt-4">
                                <div className="card-header py-0">
                                  <ul
                                    className="nav nav-tabs card-header-tabs"
                                    id="inner-tabs"
                                    role="tablist"
                                  >
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        id="new-member-tab"
                                        data-toggle="tab"
                                        href="#new-member"
                                        role="tab"
                                        aria-controls="new-member"
                                        aria-selected="true"
                                      >
                                        Anggota Baru
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="agen-tab"
                                        data-toggle="tab"
                                        href="#agen"
                                        role="tab"
                                        aria-controls="agen"
                                        aria-selected="false"
                                      >
                                        Agen
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="reseller-tab"
                                        data-toggle="tab"
                                        href="#reseller"
                                        role="tab"
                                        aria-controls="reseller"
                                        aria-selected="false"
                                      >
                                        Reseller
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="admin-tab"
                                        data-toggle="tab"
                                        href="#admin"
                                        role="tab"
                                        aria-controls="admin"
                                        aria-selected="false"
                                      >
                                        Admin
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="card-body">
                                  <div
                                    className="tab-content"
                                    id="inner-tabs-content"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="new-member"
                                      role="tabpanel"
                                      aria-labelledby="new-member-tab"
                                    >
                                      <h4>Approve Anggota Baru</h4>
                                      <div class="table-responsive pt-3">
                                        <table class="table">
                                          <thead>
                                            <tr>
                                              <th>No</th>
                                              <th>Nama</th>
                                              <th>No Whatsapp</th>
                                              <th>Username</th>
                                              <th>Role</th>
                                              <th className="text-center">
                                                Aksi
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {getNewMember.length > 0 ? (
                                              getNewMember.map(
                                                (item, index) => (
                                                  <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.phone}</td>
                                                    <td>{item.username}</td>
                                                    <td>{item.role.name}</td>
                                                    <td>
                                                      <div className="d-flex justify-content-center align-items-center gap-3">
                                                        <button
                                                          className="btn btn-sm"
                                                          onClick={() =>
                                                            handleApprove(
                                                              item.id
                                                            )
                                                          }
                                                        >
                                                          Approve
                                                        </button>
                                                        <button
                                                          className="btn btn-sm"
                                                          onClick={() =>
                                                            handleDelete(
                                                              item.id
                                                            )
                                                          }
                                                        >
                                                          Delete
                                                        </button>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="w-full text-center text-secondary">
                                                    Mohon tunggu, list anggota
                                                    baru belum ada
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="agen"
                                      role="tabpanel"
                                      aria-labelledby="agen-tab"
                                    >
                                      <div className="d-flex justify-content-between">
                                        <h4>Agen</h4>
                                      </div>
                                      <div class="table-responsive pt-3">
                                        <table class="table">
                                          <thead>
                                            <tr>
                                              <th>No</th>
                                              <th>Nama</th>
                                              <th>No Whatsapp</th>
                                              <th>Username</th>
                                              <th>Role</th>
                                              <th className="text-center">
                                                Aksi
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {getAgen.length > 0 ? (
                                              getAgen.map((item, index) => (
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{item.name}</td>
                                                  <td>{item.phone}</td>
                                                  <td>{item.username}</td>
                                                  <td>{item.role.name}</td>
                                                  <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                      <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          handleDiskon(item.id)
                                                        }
                                                      >
                                                        Diskon
                                                      </button>
                                                      <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          handleEdit(item)
                                                        }
                                                      >
                                                        Edit
                                                      </button>
                                                      <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          handleDelete(item.id)
                                                        }
                                                      >
                                                        Delete
                                                      </button>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="w-full text-center text-secondary">
                                                    Mohon tunggu, list anggota
                                                    masih loading
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="reseller"
                                      role="tabpanel"
                                      aria-labelledby="reseller-tab"
                                    >
                                      <div className="d-flex justify-content-between">
                                        <h4>Reseller</h4>
                                      </div>
                                      <div class="table-responsive pt-3">
                                        <table class="table">
                                          <thead>
                                            <tr>
                                              <th>No</th>
                                              <th>Nama</th>
                                              <th>No Whatsapp</th>
                                              <th>Username</th>
                                              <th>Role</th>
                                              <th className="text-center">
                                                Aksi
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {getReseller.length > 0 ? (
                                              getReseller.map((item, index) => (
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{item.name}</td>
                                                  <td>{item.phone}</td>
                                                  <td>{item.username}</td>
                                                  <td>{item.role.name}</td>
                                                  <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                      <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          handleDiskon(item.id)
                                                        }
                                                      >
                                                        Diskon
                                                      </button>
                                                      <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          handleEdit(item)
                                                        }
                                                      >
                                                        Edit
                                                      </button>
                                                      <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          handleDelete(item.id)
                                                        }
                                                      >
                                                        Delete
                                                      </button>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="w-full text-center text-secondary">
                                                    Mohon tunggu, list anggota
                                                    masih loading
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="admin"
                                      role="tabpanel"
                                      aria-labelledby="admin-tab"
                                    >
                                      <div className="d-flex justify-content-between">
                                        <h4>Admin</h4>
                                        <button
                                          className="btn btn-small"
                                          onClick={() =>
                                            handleShowModal("Admin")
                                          }
                                        >
                                          Tambah
                                        </button>
                                      </div>
                                      <div class="table-responsive pt-3">
                                        <table class="table">
                                          <thead>
                                            <tr>
                                              <th>No</th>
                                              <th>Nama</th>
                                              <th>No Whatsapp</th>
                                              <th>Username</th>
                                              <th>Role</th>
                                              <th className="text-center">
                                                Aksi
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {getAdmin.length > 0 ? (
                                              getAdmin.map((item, index) => (
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{item.name}</td>
                                                  <td>{item.phone}</td>
                                                  <td>{item.username}</td>
                                                  <td>{item.role.name}</td>
                                                  <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                      <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          handleDiskon(item.id)
                                                        }
                                                      >
                                                        Diskon
                                                      </button>
                                                      <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          handleEdit(item)
                                                        }
                                                      >
                                                        Edit
                                                      </button>
                                                      <button
                                                        className="btn btn-sm"
                                                        onClick={() =>
                                                          handleDelete(item.id)
                                                        }
                                                      >
                                                        Delete
                                                      </button>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="w-full text-center text-secondary">
                                                    Mohon tunggu, list anggota
                                                    masih loading
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    {selectedOrderIdDiskon && (
                                      <ModalDiscount
                                        showModalDiscount={showModalDiskon}
                                        handleClose={handleCloseDiskon}
                                        userId={selectedOrderIdDiskon}
                                        currentDiscount={currentDiscount}
                                        updateAgenDiscount={updateAgenDiscount}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="tab-pane fade"
                              id="landing-page"
                              role="tabpanel"
                              aria-labelledby="landing-page-tab"
                            >
                              <div class="card">
                                <div class="card-header pb-0">
                                  <h3>Halaman Depan</h3>
                                </div>
                              </div>
                              <div className="card mt-4">
                                <div className="card-header py-0">
                                  <ul
                                    className="nav nav-tabs card-header-tabs"
                                    id="inner-tabs"
                                    role="tablist"
                                  >
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        id="info-toko-tab"
                                        data-toggle="tab"
                                        href="#info-toko"
                                        role="tab"
                                        aria-controls="info-toko"
                                        aria-selected="true"
                                      >
                                        Info Toko
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="carousel-tab"
                                        data-toggle="tab"
                                        href="#carousel"
                                        role="tab"
                                        aria-controls="carousel"
                                        aria-selected="false"
                                      >
                                        Carousel
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="produk-tab"
                                        data-toggle="tab"
                                        href="#produk"
                                        role="tab"
                                        aria-controls="produk"
                                        aria-selected="false"
                                      >
                                        Produk
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="metode-pembayaran-tab"
                                        data-toggle="tab"
                                        href="#metode-pembayaran"
                                        role="tab"
                                        aria-controls="metode-pembayaran"
                                        aria-selected="false"
                                      >
                                        Metode Pembayaran
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="metode-pengiriman-tab"
                                        data-toggle="tab"
                                        href="#metode-pengiriman"
                                        role="tab"
                                        aria-controls="metode-pengiriman"
                                        aria-selected="false"
                                      >
                                        Metode Pengiriman
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="menu-bawah-tab"
                                        data-toggle="tab"
                                        href="#menu-bawah"
                                        role="tab"
                                        aria-controls="menu-bawah"
                                        aria-selected="false"
                                      >
                                        Menu Bawah
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="tentangKami-tab"
                                        data-toggle="tab"
                                        href="#tentangKami"
                                        role="tab"
                                        aria-controls="tentangKami"
                                        aria-selected="false"
                                      >
                                        Tentang Kami
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div className="card-body">
                                  <div
                                    className="tab-content"
                                    id="inner-tabs-content"
                                  >
                                    <div
                                      className="tab-pane fade show active"
                                      id="info-toko"
                                      role="tabpanel"
                                      aria-labelledby="info-toko-tab"
                                    >
                                      <h4>Info Toko</h4>
                                      <form
                                        method="post"
                                        name="store"
                                        onSubmit={handleFormHeaderFooter}
                                      >
                                        <div class="row pt-3">
                                          {/* menu footer */}
                                          <div class="form-group col-md-6">
                                            <label>
                                              Nama Toko{" "}
                                              <span class="required">*</span>
                                            </label>
                                            <input
                                              required
                                              class="form-control h-75"
                                              name="store_name"
                                              type="text"
                                              value={storeName}
                                              onChange={(e) =>
                                                setStoreName(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div class="form-group col-md-6">
                                            <label>
                                              No Whatsapp{" "}
                                              <span class="required">*</span>
                                            </label>
                                            <input
                                              required
                                              class="form-control h-75"
                                              name="whatsapp"
                                              value={whatsapp}
                                              onChange={(e) =>
                                                setWhatsapp(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div class="form-group col-md-12">
                                            <label>
                                              Deskripsi Toko{" "}
                                              <span class="required">*</span>
                                            </label>
                                            <QuillEditor
                                              value={description}
                                              onChange={setDescription}
                                            />
                                          </div>
                                          {/* menu header */}
                                          <div class="form-group col-md-12">
                                            <label>
                                              Headline Produk
                                              <span class="required">*</span>
                                            </label>
                                            <input
                                              required
                                              class="form-control h-75"
                                              name="headline"
                                              type="text"
                                              value={headline}
                                              onChange={(e) =>
                                                setHeadline(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div class="form-group col-md-12">
                                            <label>
                                              Gambar Pop Up{" "}
                                              <span class="required">*</span>
                                            </label>
                                            <br />
                                            {existingPopup && (
                                              <img
                                                src={
                                                  popup
                                                    ? changePopup
                                                    : urlAPI + existingPopup
                                                }
                                                alt="Popup"
                                                style={{
                                                  maxWidth: "100px",
                                                  marginBottom: "10px",
                                                }}
                                              />
                                            )}
                                            <input
                                              class="form-control h-75 border-0"
                                              name="popup"
                                              type="file"
                                              onChange={handlePopupChange}
                                            />
                                          </div>
                                          {/* <div className="form-check form-switch col-md-12">
                                            <label
                                              className="form-check-label"
                                              htmlFor="flexSwitchCheckChecked"
                                            >
                                              Pop up status
                                            </label>
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              role="switch"
                                              id="flexSwitchCheckChecked"
                                              onChange={(e) => {
                                                setActivePopup(
                                                  e.target.checked
                                                );
                                              }}
                                              checked={activePopup}
                                            />
                                          </div> */}
                                          <div className="switch-container col-md-12 d-flex align-items-center">
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                  setActivePopup(
                                                    e.target.checked
                                                  );
                                                }}
                                                checked={activePopup}
                                              />
                                              <span className="slider"></span>
                                            </label>
                                            <span className="ms-2">
                                              Aktifkan Gambar Pop Up
                                            </span>
                                          </div>

                                          {/* tombol update footer - header */}
                                          <div class="col-md-12 pt-2">
                                            <button
                                              type="submit"
                                              class="btn btn-fill-out submit font-weight-bold"
                                              name="submit"
                                              value="Submit"
                                            >
                                              Simpan
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="carousel"
                                      role="tabpanel"
                                      aria-labelledby="carousel-tab"
                                    >
                                      <div className="d-flex justify-content-between">
                                        <h4>Carousel</h4>
                                        <button
                                          className="btn btn-small"
                                          onClick={() =>
                                            handleShowModal("Carousel")
                                          }
                                        >
                                          Tambah
                                        </button>
                                      </div>
                                      <div class="table-responsive pt-3">
                                        <table class="table text-center">
                                          <thead>
                                            <tr>
                                              <th>No</th>
                                              <th>Nama</th>
                                              <th>Gambar</th>
                                              <th>Aksi</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {carouselList.length > 0 ? (
                                              carouselList.map(
                                                (item, index) => (
                                                  <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.title}</td>
                                                    <td>
                                                      <img
                                                        src={
                                                          urlAPI +
                                                          item.image_url
                                                        }
                                                        width={100}
                                                        alt=""
                                                      />
                                                    </td>
                                                    <td>
                                                      <div className="d-flex justify-content-center align-items-center gap-3">
                                                        <a
                                                          className="btn-small d-block"
                                                          onClick={() =>
                                                            handleEditCarousel(
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          className="btn-small d-block"
                                                          onClick={() =>
                                                            handleDeleteCarousel(
                                                              item.id
                                                            )
                                                          }
                                                        >
                                                          Delete
                                                        </a>
                                                        {/* <button className="btn btn-sm" onClick={() => handleEdit(item)}>Edit</button>
                                                                                    <button className="btn btn-sm" onClick={() => handleDelete(item.id)}>Delete</button> */}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="w-full text-center text-secondary">
                                                    Mohon tunggu, list masih
                                                    loading
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="metode-pembayaran"
                                      role="tabpanel"
                                      aria-labelledby="metode-pembayaran-tab"
                                    >
                                      <form
                                        method="post"
                                        name="store"
                                        onSubmit={handleFormPaymentSet}
                                      >
                                        <div class="row pb-3">
                                          <div class="form-group col-md-6">
                                            <label>
                                              Nama Menu{" "}
                                              <span class="required">*</span>
                                            </label>
                                            <input
                                              required=""
                                              class="form-control h-75"
                                              name="payment_menu"
                                              type="text"
                                              value={paymentName}
                                              onChange={(e) =>
                                                setPaymentName(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div class="col-md-6 pt-2 mt-auto">
                                            <button
                                              type="submit"
                                              class="btn btn-fill-out submit font-weight-bold"
                                              name="submit"
                                              value="Submit"
                                            >
                                              Simpan
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                      <div className="d-flex justify-content-between">
                                        <h4>Metode Pembayaran</h4>
                                        <button
                                          className="btn btn-small"
                                          onClick={() =>
                                            handleShowModal("Metode Pembayaran")
                                          }
                                        >
                                          Tambah
                                        </button>
                                      </div>
                                      <div class="table-responsive pt-3">
                                        <table class="table text-center">
                                          <thead>
                                            <tr>
                                              <th>No</th>
                                              <th>Nama</th>
                                              <th>Gambar</th>
                                              <th>Aksi</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {paymentList.length > 0 ? (
                                              paymentList.map((item, index) => (
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{item.name}</td>
                                                  <td>
                                                    <img
                                                      src={
                                                        urlAPI + item.image_url
                                                      }
                                                      width={50}
                                                      alt=""
                                                    />
                                                  </td>
                                                  <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                      <a
                                                        className="btn-small d-block"
                                                        onClick={() =>
                                                          handleEditPayment(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Edit
                                                      </a>
                                                      <a
                                                        className="btn-small d-block"
                                                        onClick={() =>
                                                          handleDeletePayment(
                                                            item.id
                                                          )
                                                        }
                                                      >
                                                        Delete
                                                      </a>
                                                      {/* <button className="btn btn-sm" onClick={() => handleEdit(item)}>Edit</button>
                                                                                    <button className="btn btn-sm" onClick={() => handleDelete(item.id)}>Delete</button> */}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="w-full text-center text-secondary">
                                                    Mohon tunggu, list masih
                                                    loading
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="metode-pengiriman"
                                      role="tabpanel"
                                      aria-labelledby="metode-pengiriman-tab"
                                    >
                                      <form
                                        method="post"
                                        name="store"
                                        onSubmit={handleFormShipmentSet}
                                      >
                                        <div class="row pb-3">
                                          <div class="form-group col-md-6">
                                            <label>
                                              Nama Menu{" "}
                                              <span class="required">*</span>
                                            </label>
                                            <input
                                              required=""
                                              class="form-control h-75"
                                              name="shipment_menu"
                                              type="text"
                                              value={shipmentName}
                                              onChange={(e) =>
                                                setShipmentName(e.target.value)
                                              }
                                            />
                                          </div>
                                          <div class="col-md-6 pt-2 mt-auto">
                                            <button
                                              type="submit"
                                              class="btn btn-fill-out submit font-weight-bold"
                                              name="submit"
                                              value="Submit"
                                            >
                                              Simpan
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                      <div className="d-flex justify-content-between">
                                        <h4>Metode Pengiriman</h4>
                                        <button
                                          className="btn btn-small"
                                          onClick={() =>
                                            handleShowModal("Metode Pengiriman")
                                          }
                                        >
                                          Tambah
                                        </button>
                                      </div>
                                      <div class="table-responsive pt-3">
                                        <table class="table text-center">
                                          <thead>
                                            <tr>
                                              <th>No</th>
                                              <th>Nama</th>
                                              <th>Gambar</th>
                                              <th>Aksi</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {shipmentList.length > 0 ? (
                                              shipmentList.map(
                                                (item, index) => (
                                                  <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                      <img
                                                        src={
                                                          urlAPI +
                                                          item.image_url
                                                        }
                                                        width={50}
                                                        alt=""
                                                      />
                                                    </td>
                                                    <td>
                                                      <div className="d-flex justify-content-center align-items-center gap-3">
                                                        <a
                                                          className="btn-small d-block"
                                                          onClick={() =>
                                                            handleEditShipment(
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          className="btn-small d-block"
                                                          onClick={() =>
                                                            handleDeleteShipment(
                                                              item.id
                                                            )
                                                          }
                                                        >
                                                          Delete
                                                        </a>
                                                        {/* <button className="btn btn-sm" onClick={() => handleEdit(item)}>Edit</button>
                                                                                    <button className="btn btn-sm" onClick={() => handleDelete(item.id)}>Delete</button> */}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="w-full text-center text-secondary">
                                                    Mohon tunggu, list masih
                                                    loading
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="menu-bawah"
                                      role="tabpanel"
                                      aria-labelledby="menu-bawah-tab"
                                    >
                                      <div className="d-flex justify-content-between">
                                        <h4>Menu Bawah</h4>
                                        <button
                                          className="btn btn-small"
                                          onClick={() =>
                                            handleShowModal("Menu Bawah")
                                          }
                                        >
                                          Tambah
                                        </button>
                                      </div>
                                      <div class="table-responsive pt-3">
                                        <table class="table text-center">
                                          <thead>
                                            <tr>
                                              <th>No</th>
                                              <th>Nama</th>
                                              <th>Link</th>
                                              <th>Aksi</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {menuFooter.length > 0 ? (
                                              menuFooter.map((item, index) => (
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{item.title}</td>
                                                  <td>
                                                    {renderTruncatedHTML(
                                                      item.url,
                                                      50
                                                    )}
                                                  </td>
                                                  <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                      <a
                                                        className="btn-small d-block"
                                                        onClick={() =>
                                                          handleEditMenuFooter(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Edit
                                                      </a>
                                                      <a
                                                        className="btn-small d-block"
                                                        onClick={() =>
                                                          handleDeleteMenuFooter(
                                                            item.id
                                                          )
                                                        }
                                                      >
                                                        Delete
                                                      </a>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="w-full text-center text-secondary">
                                                    Mohon tunggu, list masih
                                                    loading
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="produk"
                                      role="tabpanel"
                                      aria-labelledby="produk-tab"
                                    >
                                      <div className="d-flex justify-content-between">
                                        <h4>Produk berdasarkan kategori</h4>
                                        <button
                                          className="btn btn-small"
                                          onClick={() =>
                                            handleShowModal("Tampilan Produk")
                                          }
                                        >
                                          Tambah
                                        </button>
                                      </div>
                                      <div class="table-responsive pt-3">
                                        <table class="table text-center">
                                          <thead>
                                            <tr>
                                              <th>No</th>
                                              <th>Nama</th>
                                              <th>Kategori</th>
                                              <th>Tampilan</th>
                                              <th>Aksi</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {sectionList.length > 0 ? (
                                              sectionList.map((item, index) => {
                                                const catID = item.category_id;
                                                return (
                                                  <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                      {categories
                                                        .filter(
                                                          (category) =>
                                                            category.category_id ===
                                                            parseInt(catID)
                                                        )
                                                        .map(
                                                          (category) =>
                                                            category.category_name
                                                        )}
                                                    </td>
                                                    {/* <td>{item.category_id}</td> */}
                                                    <td>
                                                      {item.pageView == "large"
                                                        ? "5 Produk"
                                                        : "6 Produk"}
                                                    </td>
                                                    <td>
                                                      <div className="d-flex justify-content-center align-items-center gap-3">
                                                        <a
                                                          className="btn-small d-block"
                                                          onClick={() =>
                                                            handleEditSection(
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Edit
                                                        </a>
                                                        <a
                                                          className="btn-small d-block"
                                                          onClick={() =>
                                                            handleDeleteSection(
                                                              item.id
                                                            )
                                                          }
                                                        >
                                                          Delete
                                                        </a>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                            ) : (
                                              <tr>
                                                <td colSpan={5}>
                                                  <div className="w-full text-center text-secondary">
                                                    Mohon tunggu, list masih
                                                    loading
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="tentangKami"
                                      role="tabpanel"
                                      aria-labelledby="tentangKami-tab"
                                    >
                                      <h4>Tentang Kami</h4>
                                      <form
                                        method="post"
                                        onSubmit={handleSubmitAbout}
                                      >
                                        <div class="row pt-3">
                                          {/* menu footer */}
                                          <div class="form-group col-md-12">
                                            <label>
                                              Judul{" "}
                                              <span class="required">*</span>
                                            </label>
                                            <input
                                              required
                                              class="form-control h-75"
                                              name="about_title"
                                              type="text"
                                              value={aboutTitle}
                                              onChange={(e) =>
                                                setAboutTitle(e.target.value)
                                              }
                                            />
                                          </div>

                                          <div class="form-group col-md-12">
                                            <label>
                                              Deskripsi{" "}
                                              <span class="required">*</span>
                                            </label>
                                            <QuillEditor
                                              name
                                              value={aboutDescription}
                                              onChange={setAboutDescription}
                                            />
                                          </div>

                                          <div class="form-group col-md-12">
                                            <label>
                                              Gambar Tentang Kami (Opsional)
                                            </label>
                                            <br />
                                            {existingImage && (
                                              <img
                                                src={
                                                  aboutImage
                                                    ? changeABoutImage
                                                    : urlAPI + existingImage
                                                }
                                                alt={aboutTitle}
                                                style={{
                                                  maxWidth: "100px",
                                                  marginBottom: "10px",
                                                }}
                                              />
                                            )}

                                            <input
                                              class="form-control h-75 border-0"
                                              name="about_image"
                                              type="file"
                                              accept="image/jpeg, image/png, image/jpg, image/gif, image/svg+xml"
                                              onChange={handleAboutImageChange}
                                            />
                                          </div>
                                          {/* tombol update footer - header */}
                                          <div class="col-md-12 pt-2">
                                            <button
                                              type="submit"
                                              class="btn btn-fill-out submit font-weight-bold"
                                              name="submit"
                                              value="Submit"
                                            >
                                              Simpan
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                  <ModalComponent
                                    showModal={showModal}
                                    handleClose={handleCloseModal}
                                    activeTab={activeTab}
                                    editMode={editMode}
                                    editData={editData}
                                    setEditData={setEditData}
                                    showAlert={showAlert}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      <div
                        class="tab-pane fade"
                        id="address"
                        role="tabpanel"
                        aria-labelledby="address-tab"
                      >
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="card mb-3 mb-lg-0">
                              <div class="card-header">
                                <h3 class="mb-0">Billing Address</h3>
                              </div>
                              <div class="card-body">
                                <address>
                                  3522 Interstate
                                  <br />
                                  75 Business Spur,
                                  <br />
                                  Sault Ste. <br />
                                  Marie, MI 49783
                                </address>
                                <p>New York</p>
                                <a href="#" class="btn-small">
                                  Edit
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="card">
                              <div class="card-header">
                                <h5 class="mb-0">Shipping Address</h5>
                              </div>
                              <div class="card-body">
                                <address>
                                  4299 Express Lane
                                  <br />
                                  Sarasota, <br />
                                  FL 34249 USA <br />
                                  Phone: 1.941.227.4444
                                </address>
                                <p>Sarasota</p>
                                <a href="#" class="btn-small">
                                  Edit
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="discounts"
                        role="tabpanel"
                        aria-labelledby="discounts-tab"
                      >
                        <div class="card">
                          <div class="card-header">
                            <h3 class="mb-0">Pengaturan Besaran Diskon (%)</h3>
                          </div>
                          <div class="card-body">
                            {(userInfo && userInfo.user.role_id === "1") ||
                              (userInfo.user.role_id === "4" && (
                                <form
                                  className="contact-form-style my-4"
                                  onSubmit={handleDiscountSubmit}
                                >
                                  <div className="input-style mb-20">
                                    <label className="w-100">Diskon Agen</label>
                                    <input
                                      name="agen_discount"
                                      placeholder="Diskon Agen"
                                      type="text"
                                      className="w-50"
                                      value={agenDiscount}
                                      onChange={(e) =>
                                        handleDiscountInputChange(
                                          e,
                                          setAgenDiscount
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="input-style mb-20">
                                    <label className="w-100">
                                      Diskon Reseller
                                    </label>
                                    <input
                                      name="reseller_discount"
                                      placeholder="Diskon Reseller"
                                      type="text"
                                      className="w-50"
                                      value={resellerDiscount}
                                      onChange={(e) =>
                                        handleDiscountInputChange(
                                          e,
                                          setResellerDiscount
                                        )
                                      }
                                    />
                                  </div>
                                  <button
                                    className="submit submit-auto-width"
                                    type="submit"
                                  >
                                    Set
                                  </button>
                                </form>
                              ))}
                          </div>
                        </div>
                      </div>
                      {userInfo && (
                        <div
                          className="tab-pane fade show"
                          id="orders"
                          role="tabpanel"
                        >
                          <div className="card">
                            <div className="card-header">
                              <h3 className="mb-0">Pesanan Anda</h3>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Nomor Pesanan</th>
                                      <th>Tanggal Pesanan</th>
                                      <th>Status</th>
                                      <th>Total</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {orderHistory.length > 0 ? (
                                      orderHistory.map((order) => (
                                        <tr key={order.id}>
                                          <td>#{order.order_id}</td>
                                          <td>
                                            {new Date(
                                              order.created_at
                                            ).toLocaleDateString()}
                                          </td>
                                          <td>{order.status}</td>
                                          <td>
                                            {formatCurrency(
                                              Number(order.total)
                                            )}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="4">Belum ada order</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {(userInfo && userInfo.user.role_id === "1") ||
                        (userInfo.user.role_id === "4" && (
                          <div
                            className="tab-pane fade"
                            id="orders-approval"
                            role="tabpanel"
                          >
                            <div className="card">
                              <div className="card-header">
                                <h3 className="mb-0">Setujui Pesanan</h3>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-lg-8">
                                    <ul className="nav nav-tabs pb-2">
                                      <li className="nav-item w-50 text-center">
                                        <a
                                          className={`nav-link ${
                                            activeTabApproval === "1"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            setActiveTabApproval("1")
                                          }
                                        >
                                          Sudah Disetujui
                                        </a>
                                      </li>
                                      <li className="nav-item w-50 text-center">
                                        <a
                                          className={`nav-link ${
                                            activeTabApproval === "0"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            setActiveTabApproval("0")
                                          }
                                        >
                                          Belum Disetujui
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="tab-content">
                                  <div
                                    className={`tab-pane ${
                                      activeTabApproval === "1" ? "active" : ""
                                    }`}
                                  >
                                    <div className="table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>Nomor Pesanan</th>
                                            <th>Tanggal Pesanan</th>
                                            <th>Tanggal Persetujuan</th>
                                            <th>Total</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {approvalOrders.length > 0 ? (
                                            approvalOrders.map((order) => (
                                              <tr key={order.id}>
                                                <td>#{order.order_id}</td>
                                                <td>
                                                  {new Date(
                                                    order.created_at
                                                  ).toLocaleDateString()}
                                                </td>
                                                <td>
                                                  {new Date(
                                                    order.updated_at
                                                  ).toLocaleDateString()}
                                                </td>
                                                <td>
                                                  {formatCurrency(
                                                    Number(order.total)
                                                  )}
                                                </td>
                                              </tr>
                                            ))
                                          ) : (
                                            <>
                                              <p>Belum ada order</p>
                                            </>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div
                                    className={`tab-pane ${
                                      activeTabApproval === "0" ? "active" : ""
                                    }`}
                                  >
                                    <div className="table-responsive">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th>Nomor Pesanan</th>
                                            <th>Tanggal Pesanan</th>
                                            <th>Total</th>
                                            <th>Aksi</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {unApprovalOrders.length > 0 ? (
                                            unApprovalOrders.map((order) => (
                                              <tr key={order.id}>
                                                <td>#{order.order_id}</td>
                                                <td>
                                                  {new Date(
                                                    order.created_at
                                                  ).toLocaleDateString()}
                                                </td>

                                                <td>
                                                  {formatCurrency(
                                                    Number(order.total)
                                                  )}
                                                </td>
                                                <td>
                                                  <button
                                                    className="btn btn-primary"
                                                    onClick={() =>
                                                      handleApproveOrder(
                                                        order.order_id
                                                      )
                                                    }
                                                  >
                                                    Setujui
                                                  </button>
                                                </td>
                                              </tr>
                                            ))
                                          ) : (
                                            <>
                                              <p className="text-center">
                                                Belum ada data
                                              </p>
                                            </>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Account;
